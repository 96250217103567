import React, { useImperativeHandle } from 'react'
import { Text, Input, Button, useInput, Divider, useToasts } from '@geist-ui/core'
import Lambda from '@geist-ui/icons/lambda'
import Layers from '@geist-ui/icons/layers'
import { SettingCard } from './setting-card'
import { formats } from '~pures'

const shortcuts = [
  {
    name: '5m',
    value: 1000 * 60 * 5,
  },
  {
    name: '10m',
    value: 1000 * 60 * 10,
  },
  {
    name: '30m',
    value: 1000 * 60 * 30,
  },
  {
    name: '1h',
    value: 1000 * 60 * 60,
  },
  {
    name: '6h',
    value: 1000 * 60 * 60 * 6,
  },
  {
    name: '12h',
    value: 1000 * 60 * 60 * 12,
  },
]
type IntervalShortcutsProps = {
  onChange: (value: number) => void
}
const IntervalShortcuts: React.FC<IntervalShortcutsProps> = ({ onChange }) => {
  return (
    <div className="shortcuts">
      {shortcuts.map(({ name, value }) => (
        <div className="item" key={value}>
          <Button auto scale={1 / 2} onClick={() => onChange(value)}>
            {name}
          </Button>
        </div>
      ))}
      <style jsx>{`
        .shortcuts {
          display: flex;
          width: 100%;
          justify-content: flex-end;
          flex-wrap: wrap;
        }
        .item {
          margin: 5px;
        }
      `}</style>
    </div>
  )
}

export type LambdaIntervalValues = {
  interval: number
  concurrency: number
} | null
export type LambdaIntervalRef = {
  getValuesBeforeSubmit: () => LambdaIntervalValues
}
export type LambdaIntervalProps = {
  interval?: number
  concurrency?: number
}

export const LambdaInterval = React.forwardRef<
  LambdaIntervalRef,
  LambdaIntervalProps
>(({ interval, concurrency }, ref) => {
  const { setToast } = useToasts()
  const {
    state: intervalState,
    setState: setIntervalState,
    bindings: intervalBindings,
  } = useInput(`${interval || shortcuts.find(item => item.name === '5m')!.value}`)
  const { state: concurrencyState, bindings: concurrencyBindings } = useInput(
    `${concurrency || '1'}`,
  )
  useImperativeHandle(ref, () => ({
    getValuesBeforeSubmit,
  }))

  const validate = () => {
    if (!intervalState) return 'Interval is required'
    if (+intervalState <= 0) return 'Interval must be greater than 0'
    if (Number.isNaN(+intervalState)) return 'Interval must be a number'
    if (!Number.isInteger(+intervalState)) return 'Interval must be an integer'
    if (!concurrencyState) return 'Concurrency is required'
    if (Number.isNaN(+concurrencyState)) return 'Concurrency must be a number'
    if (!Number.isInteger(+concurrencyState)) return 'Concurrency must be an integer'
    if (+concurrencyState < 1 || +concurrencyState > 100)
      return 'Concurrency must be between 1 and 100'
    return true
  }
  const getValuesBeforeSubmit = (): LambdaIntervalValues => {
    const message = validate()
    if (message !== true) {
      setToast({ text: message, type: 'warning' })
      return null
    }
    return {
      interval: +intervalState,
      concurrency: +concurrencyState,
    }
  }

  const chengHandler = (val: number) => {
    setIntervalState(`${val}`)
  }

  return (
    <SettingCard>
      <div className="item">
        <Text h4 font="20px" my={0}>
          Lambda interval
        </Text>
        <Text mt={0} font="14px" type="secondary">
          After every specified millisecond interval, Lambda will be called by queue.
        </Text>
        <Input
          labelRight={formats.toDurations(intervalState) || ''}
          icon={<Lambda />}
          w="50%"
          scale={2 / 3}
          {...intervalBindings}
        />
        <div className="fixed">
          <IntervalShortcuts onChange={chengHandler} />
        </div>
      </div>
      <Divider mx="40px" />
      <div className="item">
        <Text h4 font="20px" my={0}>
          Concurrency
        </Text>
        <Text mt={0} font="14px" type="secondary">
          Number of stateless function triggers per unit time.
        </Text>
        <Input
          htmlType="number"
          icon={<Layers />}
          w="200px"
          scale={2 / 3}
          {...concurrencyBindings}
        />
      </div>
      <style jsx>{`
        .item {
          padding: 16px 0;
        }
        .fixed {
          position: absolute;
          top: 16px;
          right: 16px;
          width: 50%;
        }
      `}</style>
    </SettingCard>
  )
})
