import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { collections } from '~pures'
import { MestUser } from '@mest-fe/sso'
import { usersAPI } from '../apis'

export type AppError = {
  id: string
  message: string
  redirectPath?: string | null
  displayed?: boolean
}

export type AppConfigsState = {
  token: string | null
  user: MestUser | null
  errors: AppError[]
  themeType: 'dark' | 'light'
}

const initialState: AppConfigsState = {
  token: null,
  errors: [],
  user: null,
  themeType: 'light',
}

export const appConfigsSlice = createSlice({
  name: 'app-configs',
  initialState,
  reducers: {
    updateThemeType: (
      state,
      action: PayloadAction<AppConfigsState['themeType']>,
    ) => {
      state.themeType = action.payload
    },
    updateToken: (state, action: PayloadAction<string | null>) => {
      state.token = action.payload
    },
    updateUser: (state, action: PayloadAction<MestUser | null>) => {
      state.user = action.payload
    },
    appendErrors: (state, action: PayloadAction<Omit<AppError, 'id'>>) => {
      const input = action.payload
      const hasError = state.errors.find(err => {
        return (
          err.message === input.message && err.redirectPath === input.redirectPath
        )
      })
      if (hasError) return
      state.errors.push({
        ...action.payload,
        id: collections.generateId(),
      })
    },
    displayErrorById: (state, action: PayloadAction<string>) => {
      state.errors = state.errors.map(item => {
        if (item.id !== action.payload) return item
        return {
          ...item,
          displayed: true,
        }
      })
    },
    removeErrorById: (state, action: PayloadAction<string>) => {
      state.errors = state.errors.filter(item => item.id !== action.payload)
    },
  },
  extraReducers: ({ addMatcher }) => {
    addMatcher(usersAPI.endpoints.auth.matchFulfilled, (state, action) => {
      if (action.payload) {
        state.user = action.payload
      }
    })
    addMatcher(usersAPI.endpoints.logout.matchFulfilled, state => {
      state.user = null
    })
  },
})

export const appConfigsActions = appConfigsSlice.actions
