import React from 'react'
import { useGetLambdasQuery } from '~store'
import { Grid, useInput } from '@geist-ui/core'
import { MestLoading, LambdaItem, LambdaListActions } from '~cps'

export const Lambdas: React.FC<unknown> = () => {
  const { bindings, state } = useInput('')
  const { data, isLoading, isFetching } = useGetLambdasQuery({
    filter: state || undefined,
  })

  return (
    <div>
      <LambdaListActions {...bindings} placeholder="Lambda Name" />
      <MestLoading visible={isLoading || isFetching} empty={data?.length === 0}>
        <div className="list">
          <Grid.Container gap={2}>
            {data?.map((item, index) => (
              <LambdaItem key={index} lambda={item} />
            ))}
          </Grid.Container>
        </div>
      </MestLoading>

      <style jsx>{`
        .list {
          width: 100%;
          height: auto;
        }
      `}</style>
    </div>
  )
}
