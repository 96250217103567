import React, { useEffect, useRef, useState } from 'react'
import { TaskListWithRelations } from '~store'
import { Collapse, GeistUIThemes, Text, useClasses, useTheme } from '@geist-ui/core'
import { formats } from '~pures'
import { HybridLink } from '~cps'
import autoAnimate from '@formkit/auto-animate'
import FileFunction from '@geist-ui/icons/fileFunction'
import FileFunctionFill from '@geist-ui/icons/fileFunctionFill'

export type TaskListWithRelationsStacked = TaskListWithRelations[number] & {
  children?: TaskListWithRelations
}

export type TaskItemProps = {
  task: TaskListWithRelationsStacked
}

const getTaskCodeColor = (
  code: string | null,
  palette: GeistUIThemes['palette'],
) => {
  if (!code) return palette.warning
  if (code === '204') return palette.warning
  if (code.startsWith('2')) return palette.success
  if (code.startsWith('5')) return palette.error
  if (code.startsWith('4')) return palette.warning
  return palette.accents_6
}

export const TaskItem: React.FC<TaskItemProps> = ({ task }) => {
  const theme = useTheme()
  const parent = useRef(null)
  const [visible, setVisible] = useState(true)

  useEffect(() => {
    parent.current && autoAnimate(parent.current)
  }, [parent])

  return (
    <div className="item" ref={parent}>
      <div className="display" onClick={() => setVisible(last => !last)}>
        <Text font="14px">
          <Text span type="secondary" className="hash">
            #{formats.versionToHash(task.version)}
          </Text>{' '}
          Lambda{' '}
          <HybridLink color href={`/board/lambdas/${task.lambda.id}/histories`}>
            {task.lambda.name}
          </HybridLink>{' '}
          was triggered {task.children?.length || 1} times
        </Text>
        <div className="date">
          <Text font="13px" type="secondary">
            {formats.dateToDurations(task.createdAt)} ago
          </Text>
        </div>
      </div>
      {visible && task?.children && (
        <div className="dropdown">
          {task.children.map((item, index) => (
            <div className="child" key={index}>
              {index % 2 === 0 ? <FileFunction /> : <FileFunctionFill />}
              {item.current}/{item.concurrency} times by {item.from}, ended on{' '}
              {formats.toDate(item.createdAt)}
              <span>[+{+(item.performanceTime || 1) / 1000}s]</span>
              <div
                className="response"
                style={{
                  color: getTaskCodeColor(item.responseCode, theme.palette),
                }}>
                {item.responseCode}
              </div>
            </div>
          ))}
        </div>
      )}
      <style jsx>{`
        .item {
          width: 100%;
          margin-bottom: 10px;
        }
        .display {
          display: flex;
          width: 100%;
          justify-content: space-between;
          cursor: pointer;
        }
        .item :global(.hash) {
          font-size: 14px;
          color: ${theme.palette.secondary};
          text-transform: uppercase;
          font-family: ${theme.font.prism};
        }
        .date {
        }
        .dropdown {
          width: 100%;
          height: auto;
          margin-top: -5px;
          padding: 8px 16px;
          background-color: ${theme.palette.background};
          border-radius: ${theme.layout.radius};
        }
        .child {
          height: 30px;
          font-size: 14px;
          display: flex;
          align-items: center;
          position: relative;
        }
        .child span {
          color: ${theme.palette.accents_5};
          margin-left: 5px;
        }
        .child :global(svg) {
          width: 14px;
          height: 14px;
          margin-right: 6px;
        }
        .response {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          font-size: 12px;
        }
      `}</style>
    </div>
  )
}
