import React, { useEffect } from 'react'
import { Spinner, Text } from '@geist-ui/core'
import { useLogoutMutation } from '~store'
import { RedirectLayout } from '~cps'

export const Logout: React.FC<unknown> = () => {
  const [logout, { isLoading }] = useLogoutMutation()

  useEffect(() => {
    logout()
    window.location.href = `${window.location.origin}`
  }, [])

  return (
    <RedirectLayout>
      <div className="box">
        <Spinner scale={0.75} />
        <Text>Logging out {isLoading ? 'fetching...' : ''}</Text>
      </div>
      <style jsx>{`
        .box {
          display: inline-flex;
          align-items: center;
        }
        .box :global(.spinner) {
          margin-right: 10px;
        }
      `}</style>
    </RedirectLayout>
  )
}
