import React, { useCallback, useRef } from 'react'
import {
  Card,
  Input,
  Text,
  Spacer,
  Grid,
  Button,
  useInput,
  useToasts,
} from '@geist-ui/core'
import { isQueryError, useCreateLambdaMutation } from '~store'
import {
  LambdaInterval,
  LambdaBasic,
  LambdaAuth,
  LambdaBasicRef,
  LambdaIntervalRef,
  LambdaAuthRef,
} from '~cps'
import { useNavigate } from 'react-router-dom'

export const LambdaCreate: React.FC<unknown> = () => {
  const navigate = useNavigate()
  const { setToast } = useToasts()
  const basicRef = useRef<LambdaBasicRef>(null)
  const intervalRef = useRef<LambdaIntervalRef>(null)
  const authRef = useRef<LambdaAuthRef>(null)
  const [submit, { isLoading: creating }] = useCreateLambdaMutation()

  const createLambda = async () => {
    const basic = basicRef.current?.getValuesBeforeSubmit()
    if (!basic) return
    const interval = intervalRef.current?.getValuesBeforeSubmit()
    if (!interval) return
    const auth = authRef.current?.getValuesBeforeSubmit()
    const authBody = auth?.accessKey ? { accessKey: auth.accessKey } : {}

    const result = await submit({
      name: basic.name,
      endpoint: basic.endpoint,
      interval: interval.interval,
      concurrency: interval.concurrency,
      ...authBody,
    })
    if (isQueryError(result)) return
    setToast({
      text: `Lambda \`${result.data.name}\` has been successfully created`,
      type: 'success',
    })
    navigate('/board/lambdas')
  }

  return (
    <div className="create">
      <h2>Create Lambda</h2>
      <Text h4 font="13px" my="16px" type="secondary">
        BASIC
      </Text>
      <LambdaBasic ref={basicRef} />
      <Spacer h={2} />
      <Text h4 font="13px" my="16px" type="secondary">
        TRIGGERS
      </Text>
      <LambdaInterval ref={intervalRef} />
      <Spacer h={2} />
      <Text h4 font="13px" my="16px" type="secondary">
        OPTIONAL
      </Text>
      <LambdaAuth ref={authRef} />
      <div className="submit">
        <Button loading={creating} onClick={createLambda} type="secondary-light">
          Save
        </Button>
      </div>
      <style jsx>{`
        .create {
          margin: 20px auto;
          padding-bottom: 50px;
        }
        .submit {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-top: 30px;
        }
      `}</style>
    </div>
  )
}
