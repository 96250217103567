import React from 'react'
import { GlobalHeader, Layout } from '~cps'
import { Outlet } from 'react-router-dom'
import { useTheme } from '@geist-ui/core'
import Menus from './menus'

export const Dashboard: React.FC<unknown> = () => {
  const theme = useTheme()

  return (
    <Layout>
      <div className="header top">
        <GlobalHeader />
      </div>
      <div className="header bottom">
        <Menus />
      </div>
      <div className="limits">
        <Outlet />
      </div>
      <style jsx>{`
        .header {
          background: ${theme.palette.background};
          display: flex;
          justify-content: flex-end;
          align-items: center;
          height: auto;
        }
        .top {
          height: var(--layout-header-height);
          box-sizing: border-box;
        }
        .bottom {
          height: var(--layout-menu-height);
          overflow: hidden;
          border-bottom: 1px solid ${theme.palette.accents_2};
          display: flex;
          align-items: flex-end;
          box-sizing: border-box;
        }
        .limits {
          height: calc(
            100% - var(--layout-header-height) - var(--layout-menu-height)
          );
          min-height: calc(
            100vh - var(--layout-header-height) - var(--layout-menu-height)
          );
          max-width: calc(var(--layout-max-width) - 40px);
          margin: 0 auto;
          padding: 20px 0;
          box-sizing: border-box;
        }
      `}</style>
    </Layout>
  )
}
