import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react'
import { getCurrentConstants, getCurrentEnv } from '~configs/constants'
import { appConfigsActions } from '../slices/app-configs'
import type { RootState } from '../index'
import { getSemanticError } from '../index'

const baseHttpErrors = (status: number, error?: unknown): string | null => {
  if (`${status}`.startsWith('2')) return null

  const { title, message } = getSemanticError(error)
  if (`${status}`.startsWith('5')) return message || 'Service Unavailable'

  const isUnauthenticated = status === 401
  const isForbidden = status === 403
  const notFound = status === 404

  if (isUnauthenticated) return 'Unauthenticated, auth has expired.'
  if (isForbidden) return message || 'You do not have permission to access.'
  if (notFound) return message || 'API not found.'

  if (status > 300) return title ? `${title}: ${message}` : message

  return null
}

const getHttpRedirects = (status: number) => {
  switch (status) {
    case 401:
      return '/'
    default:
      return null
  }
}

const metaBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const constants = getCurrentConstants(getCurrentEnv())
  const { appConfigs } = (api.getState() as RootState) || {}

  const rawBaseQuery = fetchBaseQuery({
    baseUrl: constants.API_ORIGIN,
    credentials: 'include',
    mode: 'cors',

    prepareHeaders: (headers, api) => {
      // headers.set('Authorization', `Bearer ${appConfigs.token}`)
      headers.set('Content-Type', 'application/json')
      headers.set('Accept', 'application/json')
      return headers
    },
  })
  const result = await rawBaseQuery(args, api, extraOptions)
  if (!result.error) return result
  const { status } = result.error

  const message = baseHttpErrors(status as number, result.error)
  if (!message) return result

  api.dispatch(
    appConfigsActions.appendErrors({
      redirectPath: getHttpRedirects(status as number),
      message,
    }),
  )

  return Promise.reject(result.error)
}

export const BaseApi = createApi({
  reducerPath: 'api',
  baseQuery: metaBaseQuery as ReturnType<typeof fetchBaseQuery>,
  endpoints: () => ({}),
  tagTypes: ['Lambda', 'Lambdas', 'User', 'Task', 'Tasks', 'LatestTasks'],
})
