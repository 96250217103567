import React, { useState } from 'react'
import { Avatar, AvatarProps, ScaleProps } from '@geist-ui/core'
import { useAsync } from '~hooks'

export type LazyAvatarProps = AvatarProps &
  ScaleProps & {
    text: string
    src: string
  }

export const LazyAvatar: React.FC<LazyAvatarProps> = ({ text = '', src, ...props }) => {
  const [url, setUrl] = useState<string | undefined>()

  useAsync(async () => {
    if (!src) return
    try {
      await fetch(src)
      setUrl(src)
    } catch (err) {
      setUrl(undefined)
    }
  }, [src])
  return <Avatar text={text} src={url} {...props} />
}
