import React, { useMemo } from 'react'
import Highlight, { defaultProps } from 'prism-react-renderer'
import { Code } from '@geist-ui/core'
import vsDark from 'prism-react-renderer/themes/github'

export type PrismCodeProps = {
  json?: string | null
}

export const PrismCode: React.FC<PrismCodeProps> = ({ json }) => {
  const formatJson = useMemo(() => {
    if (!json) return ''
    try {
      return JSON.stringify(JSON.parse(json), null, 2)
    } catch (error) {
      return `${json}`
    }
  }, [json])

  return (
    <Highlight
      theme={vsDark}
      Prism={defaultProps.Prism}
      code={formatJson}
      language="json">
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <Code block>
          {tokens.map((line, i) => (
            <div {...getLineProps({ line, key: i })}>
              {line.map((token, key) => (
                <span {...getTokenProps({ token, key })} />
              ))}
            </div>
          ))}
        </Code>
      )}
    </Highlight>
  )
}
