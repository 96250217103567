import React from 'react'
import { Button, Input, useTheme } from '@geist-ui/core'
import Search from '@geist-ui/icons/search'
import { useNavigate } from 'react-router-dom'

export type LambdaListActionsProps = {
  value?: string
  initialValue?: string
  placeholder?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const LambdaListActions: React.FC<LambdaListActionsProps> = ({
  ...props
}) => {
  const theme = useTheme()
  const navigate = useNavigate()

  return (
    <div className="actions">
      <Input w="100%" h="40px" icon={<Search />} clearable {...props} />
      <Button h="40px" ml="25px" onClick={() => navigate('/board/lambdas/create')}>
        Create Lambda
      </Button>
      <style jsx>{`
        .actions {
          height: 40px;
          display: flex;
          align-items: center;
          margin: 0 auto 20px;
        }
        .actions :global(.with-label) {
          height: 40px;
          background-color: ${theme.palette.background};
        }
      `}</style>
    </div>
  )
}
