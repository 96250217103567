import React from 'react'
import { Button, Card, Code, Divider, Spacer, Text, useTheme } from '@geist-ui/core'
import { toDate } from 'src/pures/formats'
import { useAppConfigs, useAuthQuery } from '~store'
import { useNavigate } from 'react-router-dom'

export const Profile: React.FC<unknown> = () => {
  const theme = useTheme()
  const { user } = useAppConfigs()
  const navigate = useNavigate()
  useAuthQuery(undefined, { skip: !!user })

  return (
    <div className="profile">
      <Spacer h="10px" />
      <Text h3>Profile (ME)</Text>
      <Card py="5px" px="10px">
        <Text>
          {user?.name} <Spacer inline w="5px" />
          <Text span font="12px" type="secondary">
            [update {toDate(user?.updatedAt!, 'YYYY/MM/DD')}]
          </Text>
        </Text>
        <Divider my="15px" />
        <Text>{user?.email}</Text>
        <Divider my="15px" />
        <Text>
          Role: <Code>{user?.role}</Code>
        </Text>
      </Card>
      <Spacer h="30px" />
      <Text h3>Actions</Text>
      <Card>
        <Button auto type="warning-light" onClick={() => navigate('/logout')}>
          Logout
        </Button>
      </Card>
      <style jsx>{`
        .profile {
          //font-family: ${theme.font.prism};
        }
        .profile :global(p),
        .profile :global(code) {
          font-family: ${theme.font.prism};
        }
      `}</style>
    </div>
  )
}
