import { BaseApi } from './base'
import { MestUser } from '@mest-fe/sso'

export const usersAPI = BaseApi.injectEndpoints({
  endpoints: ({ query, mutation }) => ({
    auth: query<MestUser, void>({
      query: () => '/auth',
      providesTags: ['User'],
    }),
    logout: mutation<void, void>({
      query: () => ({
        url: '/auth',
        method: 'DELETE',
        invalidatesTags: ['User'],
      }),
    }),
  }),
})

export const { useAuthQuery, useLogoutMutation } = usersAPI
