import React from 'react'
import { GridProps } from '@geist-ui/core'

export type ActionsLayoutProps = {
  justify?: GridProps['justify']
}

const defaultProps: ActionsLayoutProps = {
  justify: 'space-between',
}

export const ActionsLayout: React.FC<
  React.PropsWithChildren<ActionsLayoutProps>
> = ({ children, justify }) => {
  return (
    <div className="actions">
      {children}
      <style jsx>{`
        .actions {
          display: flex;
          height: 32px;
          width: 100%;
          margin-bottom: 20px;
          flex-direction: row;
          justify-content: ${justify};
        }
      `}</style>
    </div>
  )
}

ActionsLayout.defaultProps = defaultProps
