import { BaseApi } from './base'
import type { Lambda } from './lambdas'

export type Task = {
  id: number
  version: string
  lambdaId: number
  performanceTime: string | null
  response: string | null
  responseCode: string | null
  from: string
  current: number
  concurrency: number
  createdAt: Date
  updatedAt: Date
}

export type TaskList = Array<Task>
export type TaskListWithCount = {
  tasks: TaskList
  count: number
  total: number
}
export type TaskListWithRelations = Array<
  Task & {
    lambda: Lambda
  }
>

export const tasksAPI = BaseApi.injectEndpoints({
  endpoints: ({ query, mutation }) => ({
    getTaskOne: query<Task, { taskId: string }>({
      query: ({ taskId }) => `/lambdas/tasks/${taskId}`,
      providesTags: ['Task'],
    }),
    getLatestTasks: query<TaskListWithRelations, void>({
      query: () =>
        `/tasks/latest?relations=lambdas&sort=desc&sortKey=updateAt&per_page=20&page=1`,
      providesTags: ['LatestTasks'],
    }),
    getTasks: query<
      TaskListWithCount,
      {
        lambdaId: string
        page?: number
        filter?: string
        perPage?: number
        code?: any
        from?: any
      }
    >({
      query: ({
        lambdaId,
        page = 1,
        filter = '',
        perPage = 9,
        code = '',
        from = '',
      }) =>
        `/lambdas/${lambdaId}/tasks?page=${page}&filter=${filter}&per_page=${perPage}&count=1&code=${code}&from=${from}`,
      transformResponse(response: TaskList, meta, arg) {
        const count = meta?.response?.headers.get('Total-Count') || 0

        return {
          tasks: response,
          count: Math.ceil(+count / (arg?.perPage || 10)),
          total: +count,
        }
      },
      providesTags: ['Tasks'],
    }),
    createClone: mutation<void, { id: string | number }>({
      query: ({ id }) => ({
        url: `/tasks/${id}/clone`,
        method: 'POST',
      }),
    }),
  }),
})

export const {
  useGetTaskOneQuery,
  useGetTasksQuery,
  useGetLatestTasksQuery,
  useCreateCloneMutation,
} = tasksAPI
