import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Lambda, useGetTasksQuery } from '~store'
import { MestEmpty, MestLoading } from '~cps'
import { Text, useTheme } from '@geist-ui/core'
import { formats } from '~pures'
import { dateToDurations } from '~pures/formats'

export type LambdaSummaryProps = {
  lambda: Lambda
}

export const LambdaSummary: React.FC<LambdaSummaryProps> = ({ lambda }) => {
  const { data, isLoading, isFetching } = useGetTasksQuery(
    { lambdaId: `${lambda.id}`, perPage: 30 },
    { skip: !lambda.id },
  )

  if (isLoading || isFetching) return <MestLoading visible />
  if (data?.tasks.length === 0) return <MestEmpty visible />

  return (
    <div className="summary">
      <Text h3 font="16px" className="title">
        Summary
      </Text>
      <Text>
        Total {data?.total} runs, last run in{' '}
        {formats.toDate(data!.tasks[0].createdAt)},{' '}
        <Text span del={lambda.paused}>
          will run next in {formats.dateToDurations(lambda.nextCycleAt)}
        </Text>
        .
      </Text>
      {lambda.paused && (
        <Text type="error">
          Lambda({lambda.name}::{lambda.id}) has stopped working unless manually
          restored.
        </Text>
      )}
      <style jsx>{`
        .summary {
          width: 100%;
          border-radius: 5px;
          overflow: hidden;
        }
      `}</style>
    </div>
  )
}
