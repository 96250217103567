import React from 'react'
import { Text } from '@geist-ui/core'

export type HistoryFromProps = {
  from: string
}

export const HistoryFrom: React.FC<HistoryFromProps> = ({ from: fromType }) => {
  return (
    <div className="concurrency">
      <Text my={0} font="14px">
        {fromType}
      </Text>
      <style jsx>{`
        .concurrency {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      `}</style>
    </div>
  )
}
