import React from 'react'
import { Text } from '@geist-ui/core'
import { formats } from '~pures'

export type HistoryDateLabelProps = {
  date: Date
}

export const HistoryDateLabel: React.FC<HistoryDateLabelProps> = ({ date }) => {
  return (
    <div className="date">
      <Text my={0} type="secondary" font="14px">
        {formats.toDate(date)}
      </Text>
      <style jsx>{`
        .date {
          width: 100%;
          height: auto;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding-right: 16px;
        }
      `}</style>
    </div>
  )
}
