import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { appConfigsActions, useAppDispatch, useAuthQuery } from '~store'
import { Rainbow } from './rainbow'
import { Footer } from './footer'
import { useSSO } from '@mest-fe/sso'

export const Home: React.FC<unknown> = () => {
  const { login } = useSSO()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { isError, isLoading, data } = useAuthQuery()

  useEffect(() => {
    if (isLoading || isError || !data) return
    if (data.hd) {
      dispatch(appConfigsActions.updateUser(data))
      navigate('/board')
    }
  }, [isLoading, isError, data])

  return (
    <div className="home">
      <Rainbow onLogin={login} isRefreshing={isLoading} />
      <Footer />
      <style jsx>{`
        .home {
          width: 100vw;
          height: 100vh;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          z-index: 1;
        }
      `}</style>
    </div>
  )
}

export default Home
