/// <reference path="../node_modules/styled-jsx/index.d.ts" />
import 'inter-ui/inter.css'
import React from 'react'
import ReactDOM from 'react-dom'
import PageRouter from './routes'
import { persistor, store, useAppConfigs } from '~store'
import { Provider } from 'react-redux'
import { CssBaseline, GeistProvider } from '@geist-ui/core'
import { PersistGate } from 'redux-persist/integration/react'

const Index: React.FC<unknown> = () => {
  const { themeType } = useAppConfigs()

  return (
    <GeistProvider themeType={themeType}>
      <CssBaseline />
      <PageRouter />
      <style jsx global>{`
        html,
        body {
          box-sizing: border-box;
          height: 100%;
        }
        #root {
          width: 100%;
          height: 100%;
        }
        body::-webkit-scrollbar {
          width: var(--layout-scrollbar-width);
        }
        body::-webkit-scrollbar-thumb {
          width: var(--layout-scrollbar-width);
          border-radius: 4px;
        }
        body .backdrop .layer {
          background-color: rgba(242, 243, 249, 0.2);
        }
        #geist-ui-modal .layer {
          backdrop-filter: blur(5px);
          background: rgb(10 10 10 / 50%);
        }
        #geist-ui-modal .backdrop.backdrop-wrapper-enter-active .layer {
          opacity: 1;
        }
        :root {
          --layout-header-height: 54px;
          --layout-menu-height: 47px;
          --layout-scrollbar-width: 4px;
          --layout-max-width: 1048px;
        }
      `}</style>
    </GeistProvider>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Index />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)
