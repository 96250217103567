import React, { useMemo } from 'react'
import { TinyArea, TinyAreaConfig } from '@ant-design/plots'
import { useParams } from 'react-router-dom'
import { useGetTasksQuery } from '~store'
import { MestEmpty, MestLoading } from '~cps'
import { Text, useTheme } from '@geist-ui/core'

export const LambdaChart: React.FC<unknown> = () => {
  const theme = useTheme()
  const { lambdaId } = useParams()
  const { data, isLoading, isFetching } = useGetTasksQuery(
    { lambdaId: lambdaId!, perPage: 30 },
    { skip: !lambdaId },
  )
  const performances = useMemo<number[]>(() => {
    if (!data?.tasks) return []
    return data?.tasks.map(item =>
      item.performanceTime ? Number(item.performanceTime) : 0,
    )
  }, [data])

  const config: TinyAreaConfig = {
    height: 150,
    autoFit: false,
    data: performances,
    smooth: true,
    padding: [0, 0, 0, 0],
    style: {
      background: theme.palette.accents_1,
    },
    areaStyle: {
      fill: theme.palette.accents_2,
    },
    renderer: 'svg',
    annotations: [
      {
        type: 'line',
        start: ['min', 'mean'],
        end: ['max', 'mean'],
        text: {
          content: 'Avg',
          offsetY: -2,
          style: {
            textAlign: 'left',
            fontSize: 10,
            fill: theme.palette.warningLight,
            textBaseline: 'bottom',
          },
        },
        style: {
          stroke: theme.palette.warningLight,
        },
      },
    ],
  }

  if (isLoading || isFetching) return <MestLoading visible />
  if (data?.tasks.length === 0) return <MestEmpty visible />

  return (
    <div className="chart">
      <Text h3 font="16px" className="title">
        Performance
      </Text>
      <TinyArea {...config} />
      <style jsx>{`
        .chart {
          width: 100%;
          border-radius: 5px;
          overflow: hidden;
        }
        .chart :global(.title) {
          text-transform: uppercase;
          font-family: ${theme.font.prism};
        }
        .chart :global([data-chart-source-type='G2Plot']) {
        }
      `}</style>
    </div>
  )
}
