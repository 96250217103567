import React, { useRef } from 'react'
import {
  LambdaAuth,
  LambdaAuthRef,
  LambdaBasic,
  LambdaBasicRef, LambdaDestroy,
  LambdaInterval,
  LambdaIntervalRef,
  MestEmpty,
  MestLoading,
} from '~cps'
import { Button, Spacer, Text, useToasts } from '@geist-ui/core'
import {
  isQueryError,
  useGetLambdaOneQuery,
  useUpdateLambdaByIdMutation,
} from '~store'
import { useNavigate, useParams } from 'react-router-dom'

export const LambdasSettings: React.FC<unknown> = () => {
  const navigate = useNavigate()
  const { lambdaId } = useParams()
  const { setToast } = useToasts()
  const { data, isLoading, isFetching } = useGetLambdaOneQuery(
    { id: lambdaId! },
    { skip: !lambdaId },
  )
  const basicRef = useRef<LambdaBasicRef>(null)
  const intervalRef = useRef<LambdaIntervalRef>(null)
  const authRef = useRef<LambdaAuthRef>(null)
  const [submit, { isLoading: creating }] = useUpdateLambdaByIdMutation()

  const updateHandler = async () => {
    const basic = basicRef.current?.getValuesBeforeSubmit()
    if (!basic) return
    const interval = intervalRef.current?.getValuesBeforeSubmit()
    if (!interval) return
    const auth = authRef.current?.getValuesBeforeSubmit()
    const authBody = auth?.accessKey ? { accessKey: auth.accessKey } : {}

    const result = await submit({
      id: lambdaId!,
      body: {
        name: basic.name,
        endpoint: basic.endpoint,
        interval: interval.interval,
        concurrency: interval.concurrency,
        ...authBody,
      },
    })
    if (isQueryError(result)) return
    setToast({
      text: `Lambda \`${result.data.name}\` has been successfully created`,
      type: 'success',
    })
    navigate(`/board/lambdas/${lambdaId}`)
  }

  if (isLoading || isFetching) return <MestLoading visible />
  if (!data?.id) return <MestEmpty visible />

  return (
    <div className="setting">
      <Text h4 font="13px" my="16px" type="secondary">
        BASIC
      </Text>
      <LambdaBasic ref={basicRef} name={data.name} endpoint={data.endpoint} />
      <Spacer h={2} />
      <Text h4 font="13px" my="16px" type="secondary">
        TRIGGERS
      </Text>
      <LambdaInterval
        ref={intervalRef}
        interval={data.interval}
        concurrency={data.concurrency}
      />
      <Spacer h={2} />
      <Text h4 font="13px" my="16px" type="secondary">
        OPTIONAL
      </Text>
      <LambdaAuth ref={authRef} accessKey={data.accessKey} />
      <Spacer h={0.5} />
      <LambdaDestroy lambda={data} />
      <div className="submit">
        <Button type="secondary-light" onClick={updateHandler} loading={creating}>
          Update
        </Button>
      </div>
      <style jsx>{`
        .setting {
          margin: 20px auto;
          padding-bottom: 50px;
        }
        .submit {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-top: 30px;
        }
      `}</style>
    </div>
  )
}
