import React from 'react'
import type { LambdaList } from '~store'
import { Card, Grid, useTheme, Text } from '@geist-ui/core'
import { formats } from '~pures'
import Layers from '@geist-ui/icons/layers'
import PieChart from '@geist-ui/icons/pieChart'

export * from './lambda-list-actions'

export type LambdaItemProps = {
  lambda: LambdaList[number]
}

export const LambdaItem: React.FC<LambdaItemProps> = ({ lambda }) => {
  const theme = useTheme()

  return (
    <Grid xs={8}>
      <a href={`/board/lambdas/${lambda.id}`} className="lambda-item">
        <Card w="100%">
          <h4>{lambda.name}</h4>
          <div className="record">
            <div className="item">
              <Layers />
              <Text font="13px" my={0}>
                Total of{' '}
                <Text span type="success">
                  {lambda._count.tasks}
                </Text>{' '}
                runs.
              </Text>
            </div>
            <div className="item">
              <PieChart />
              <Text font="13px" my={0}>
                Next run in{' '}
                <Text span type="success">
                  {formats.dateToDurations(lambda.nextCycleAt)}
                </Text>
                .
              </Text>
            </div>
          </div>
          <div className="date">UPDATE AT {formats.toDate(lambda.updatedAt)}</div>
          {lambda.paused && (
            <div className="paused">
              <span className="paused-icon" /> PAUSED
            </div>
          )}
        </Card>
      </a>
      <style global jsx>{`
        .lambda-item {
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          cursor: pointer;
          display: inline-block;
          width: 100%;
          border-radius: ${theme.layout.radius};
          position: relative;
        }
        .lambda-item:hover {
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);
        }
        .date {
          display: flex;
          justify-content: flex-end;
          max-width: 100%;
          font-size: 12px;
          color: ${theme.palette.accents_4};
          transform: scale(0.8) translateX(12%);
          margin-bottom: -8px;
        }
        .record {
          display: flex;
          flex-direction: column;
          padding-bottom: 10px;
          padding-left: 5px;
          opacity: 0.7;
        }
        .item {
          width: 100%;
          height: 25px;
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
        }
        .item :global(svg) {
          width: 14px;
          height: 14px;
          margin-right: 5px;
        }
        .paused {
          position: absolute;
          top: 10px;
          right: 10px;
          width: 50%;
          height: 20px;
          font-size: 12px;
          font-weight: 600;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          color: ${theme.palette.warningLight};
          line-height: 1;
        }
        .paused-icon {
          width: 10px;
          height: 10px;
          margin-right: 5px;
          border-radius: 50%;
          background-color: ${theme.palette.warningLight};
        }
      `}</style>
    </Grid>
  )
}
