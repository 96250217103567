import React, { useMemo, useState } from 'react'
import { Popover, Text, useTheme, Themes } from '@geist-ui/core'
import { HybridLink, LazyAvatar } from '~cps'
import {
  appConfigsActions,
  useAppConfigs,
  useAppDispatch,
  useAuthQuery,
} from '~store'

const Users: React.FC<unknown> = () => {
  const theme = useTheme()
  const { user } = useAppConfigs()
  useAuthQuery(undefined, { skip: !!user })
  const dispatch = useAppDispatch()
  const [visible, setVisible] = useState<boolean>(false)
  const nextThemeType = useMemo(
    () => (theme.type === 'dark' ? 'light' : 'dark'),
    [theme.type],
  )

  const closePopup = () => {
    setVisible(false)
  }
  const changeTheme = () => {
    dispatch(appConfigsActions.updateThemeType(nextThemeType))
  }

  const content = () => (
    <>
      <Popover.Item title w="200px">
        {user?.name}
      </Popover.Item>
      <Popover.Item h="42px" mt="5px" onClick={changeTheme}>
        Switch to {nextThemeType} theme
      </Popover.Item>
      <Popover.Item h="42px" mt="5px">
        <HybridLink onRedirect={closePopup} href="/board/profile">
          Your profile
        </HybridLink>
      </Popover.Item>
      <Popover.Item line />
      <Popover.Item>
        <HybridLink onRedirect={closePopup} href="/logout">
          <Text type="warning" span>
            Logout
          </Text>
        </HybridLink>
      </Popover.Item>
    </>
  )

  return (
    <div className="user">
      <Popover
        leaveDelay={30}
        visible={visible}
        onVisibleChange={setVisible}
        trigger="hover"
        portalClassName="user-popup"
        hideArrow
        placement="bottomEnd"
        content={content}>
        <LazyAvatar
          isSquare
          w="34px"
          h="34px"
          text={user?.name || user?.givenName || 'U'}
          src={user?.picture!}
        />
      </Popover>

      <style jsx>{`
        .user {
          width: 60px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-right: 25px;
        }
        :global(.user-popup.popover div.inner) {
          padding: 10px 0;
        }
      `}</style>
    </div>
  )
}

export default Users
