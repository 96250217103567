import React from 'react'
import { Text } from '@geist-ui/core'

export type HistoryTimeLabelProps = {
  time: string | null
}

export const HistoryTimeLabel: React.FC<HistoryTimeLabelProps> = ({ time }) => {
  return (
    <div className="time">
      <Text my={0} font="14px">
        {time}
        <Text span type="secondary">
          ms
        </Text>
      </Text>
      <Text my={0} type="secondary" font="14px">
        Run Time
      </Text>
      <style jsx>{`
        .time {
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      `}</style>
    </div>
  )
}
