import React from 'react'
import { Text } from '@geist-ui/core'

export type HistoryConcurrencyProps = {
  current: number
  concurrency: number
}

export const HistoryConcurrency: React.FC<HistoryConcurrencyProps> = ({
  concurrency,
  current,
}) => {
  return (
    <div className="concurrency">
      <Text my={0} font="14px">
        {current}{' '}
        <Text span type="secondary">
          /
        </Text>{' '}
        {concurrency}
      </Text>
      <Text my={0} type="secondary" font="14px">
        Index
      </Text>
      <style jsx>{`
        .concurrency {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      `}</style>
    </div>
  )
}
