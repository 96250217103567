import { CurriedGetDefaultMiddleware } from '@reduxjs/toolkit/dist/getDefaultMiddleware'
import storage from 'redux-persist/lib/storage'
import { CombinedState, configureStore, Reducer } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import { BaseApi } from './apis'

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [BaseApi.reducerPath],
}

const middleware = (getDefaultMiddleware: CurriedGetDefaultMiddleware) => {
  return getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ['persist/PERSIST'],
    },
  }).concat(BaseApi.middleware)
}

export const getStore = <S>(rootReducer: Reducer<CombinedState<S>>) =>
  configureStore({
    reducer: persistReducer(persistConfig, rootReducer),
    middleware,
  })
