import React, { useEffect } from 'react'
import { useToasts } from '@geist-ui/core'
import { useAppConfigs, appConfigsActions } from '~store'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

export const GlobalErrors: React.FC<unknown> = () => {
  const dispatch = useDispatch()
  const { setToast } = useToasts({ placement: 'bottomLeft' })
  const { errors } = useAppConfigs()
  const navigate = useNavigate()

  const redirect = (redirectPath?: string | null) => {
    if (!redirectPath) return
    dispatch(appConfigsActions.updateToken(null))
    const timer = setTimeout(() => {
      navigate(redirectPath)
      clearTimeout(timer)
    }, 60)
  }
  const delayToRemoveErrorById = (id: string) => {
    const timer = setTimeout(() => {
      dispatch(appConfigsActions.removeErrorById(id))
      clearTimeout(timer)
    }, 4000)
  }

  useEffect(() => {
    if (errors.length === 0) return
    errors.forEach(item => {
      if (item.displayed) return delayToRemoveErrorById(item.id)
      dispatch(appConfigsActions.displayErrorById(item.id))
      setToast({
        text: item.message,
        type: 'error',
      })
      delayToRemoveErrorById(item.id)
      redirect(item.redirectPath)
    })
  }, [errors])

  return null
}
