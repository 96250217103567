import React from 'react'
import { MestNodata } from '~assets/images'
import { useTheme, Text } from '@geist-ui/core'

export type MestEmptyProps = {
  visible?: boolean
}

export const MestEmpty: React.FC<React.PropsWithChildren<MestEmptyProps>> = ({
  visible,
  children,
}) => {
  const theme = useTheme()

  if (!visible) return React.Children.count(children) ? <>{children}</> : null

  return (
    <div className="empty">
      <MestNodata />
      <Text font="12px" type="secondary">
        No Data
      </Text>
      <style jsx>{`
        .empty {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 40px;
          color: ${theme.palette.secondary};
          user-select: none;
        }
        .empty :global(svg) {
          width: 30px;
          height: 30px;
          opacity: 0.6;
        }
      `}</style>
    </div>
  )
}
