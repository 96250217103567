import React, { useImperativeHandle } from 'react'
import { Text, Input, Divider, useInput, useToasts } from '@geist-ui/core'
import { SettingCard } from './setting-card'
import Link2 from '@geist-ui/icons/link2'
import { HybridLink } from '../widgets'
import { is } from '~pures'

export type LambdaBasicProps = {
  name?: string
  endpoint?: string
}

export type LambdaBasicValues = {
  name: string
  endpoint: string
} | null

export type LambdaBasicRef = {
  getValuesBeforeSubmit: () => LambdaBasicValues
}

export const LambdaBasic = React.forwardRef<LambdaBasicRef, LambdaBasicProps>(
  ({ name, endpoint }, ref) => {
    const { setToast } = useToasts()
    const { state: nameState, bindings: nameBanding } = useInput(name || '')
    const { state: endpointState, bindings: endpointBanding } = useInput(
      endpoint || '',
    )
    const isDisabled = typeof endpoint !== 'undefined'
    useImperativeHandle(ref, () => ({
      getValuesBeforeSubmit,
    }))

    const validate = () => {
      if (!nameState) return 'Name is required'
      if (nameState.length < 3) return 'Name must be at least 3 characters'
      if (nameState.length > 30) return 'Name must be less than 30 characters'
      if (!endpointState) return 'Endpoint is required'
      if (!is.url(endpointState)) return 'Endpoint must be URL'
      return true
    }

    const getValuesBeforeSubmit = (): LambdaBasicValues => {
      const message = validate()
      if (message !== true) {
        setToast({ text: message, type: 'warning' })
        return null
      }
      return {
        name: nameState,
        endpoint: endpointState,
      }
    }

    return (
      <SettingCard>
        <div className="item">
          <Text h4 font="20px" my={0}>
            Lambda Name
          </Text>
          <Text mt={0} font="14px" type="secondary">
            A unique string to identify different functions.
          </Text>
          <Input
            w="50%"
            scale={2 / 3}
            placeholder="My favorite function"
            {...nameBanding}
          />
        </div>
        <Divider mx="40px" />
        <div className="item">
          <div className="tips">
            <HybridLink
              target="_blank"
              href="https://github.com/mest-io/porters/blob/main/README.md"
              icon
              font="12px"
              color>
              <Text font="12px">How to get endpoint of a new function</Text>
            </HybridLink>
          </div>
          <Text h4 font="20px" my={0}>
            Endpoint
          </Text>
          <Text mt={0} font="14px" type={isDisabled ? 'warning' : 'secondary'}>
            {isDisabled
              ? 'Endpoint cannot be modified after creation.'
              : 'The public gateway address for the function, which must be HTTPS.'}
          </Text>
          <Input
            disabled={isDisabled}
            icon={<Link2 />}
            w="350px"
            scale={2 / 3}
            placeholder="https://my-endpoint.porters.mest.sh/function"
            {...endpointBanding}
          />
        </div>
        <style jsx>{`
          .item {
            padding: 16px 0;
            position: relative;
          }
          .tips {
            position: absolute;
            right: 0;
            top: 6px;
            display: ${isDisabled ? 'none' : 'block'};
          }
        `}</style>
      </SettingCard>
    )
  },
)
