import React from 'react'
import { useGetLambdaOneQuery } from '~store'
import { useParams } from 'react-router-dom'
import { Button, Spacer, Text, useTheme } from '@geist-ui/core'
import {
  HybridLink,
  MestEmpty,
  MestLoading,
  LambdaChart,
  LambdaSummary,
  LambdaShortcuts,
} from '~cps'

export const LambdaDetails: React.FC<unknown> = () => {
  const theme = useTheme()
  const { lambdaId } = useParams()
  const { data, isLoading, isFetching } = useGetLambdaOneQuery(
    { id: lambdaId! },
    { skip: !lambdaId },
  )

  if (isLoading || isFetching) return <MestLoading visible />
  if (!data?.id) return <MestEmpty visible />

  return (
    <div className="lambda-overview">
      <div className="fixed-header">
        <div className="content">
          <Text h2>{data?.name}</Text>
          <div className="links">
            {data?.github && (
              <HybridLink href={data.github!} target="_blank">
                <Button scale={0.8} auto>
                  GitHub
                </Button>
              </HybridLink>
            )}
            <Spacer w={1.5} />
            <HybridLink href={data?.endpoint} target="_blank">
              <Button scale={0.8} auto type="secondary-light">
                Endpoint
              </Button>
            </HybridLink>
          </div>
        </div>
      </div>
      <Spacer />
      <LambdaSummary lambda={data} />
      <Spacer h={2} />
      <LambdaChart />
      <Spacer h={2} />
      <LambdaShortcuts />
      <style jsx>{`
        .lambda-overview {
          display: flex;
          flex-direction: column;
          padding-top: 136px;
        }
        .fixed-header {
          position: fixed;
          top: calc(var(--layout-header-height) + var(--layout-menu-height));
          left: 0;
          right: 0;
          width: 100vw;
          height: 136px;
          background-color: ${theme.palette.background};
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom: 1px solid ${theme.palette.accents_2};
        }
        .content {
          width: var(--layout-max-width);
          height: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 16px;
        }
        .links {
          display: flex;
        }
      `}</style>
    </div>
  )
}
