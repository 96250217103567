import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { appConfigsSlice } from './slices'
import { useDispatch, useSelector } from 'react-redux'
import { BaseApi } from './apis'
import { getStore } from 'src/store/store'
import { persistStore } from 'redux-persist'
export * from './helpers'
export * from './slices'
export * from './apis'

const rootReducer = combineReducers({
  [BaseApi.reducerPath]: BaseApi.reducer,
  appConfigs: appConfigsSlice.reducer,
})

export const store = getStore(rootReducer)
export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof rootReducer>

export const useAppConfigs = () =>
  useSelector<RootState, RootState['appConfigs']>(({ appConfigs }) => appConfigs)

export const useAppDispatch = () => useDispatch<AppDispatch>()
