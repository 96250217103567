import React, { useMemo } from 'react'
import { MestLoadingLight, MestLoadingDark } from '~assets/images'
import { useTheme } from '@geist-ui/core'
import { MestEmpty } from './mest-empty'

export type MestLoadingProps = {
  visible?: boolean
  empty?: boolean
  className?: string
}

export const MestLoading: React.FC<React.PropsWithChildren<MestLoadingProps>> = ({
  children,
  visible,
  empty,
  className,
}) => {
  const theme = useTheme()
  const image = theme.type === 'dark' ? MestLoadingDark : MestLoadingLight
  const childrenElements = useMemo(() => {
    if (!className) return children
    return <div className={className}>{children}</div>
  }, [children, className])

  if (!visible && !empty)
    return React.Children.count(children) ? <>{childrenElements}</> : null
  if (empty) return <MestEmpty visible />

  return (
    <div className="loading">
      <img src={image} alt="Loading" draggable={false} />
      <style jsx>{`
        .loading {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          padding-top: 40px;
          user-select: none;
        }
        img {
          width: 40px;
          height: 40px;
          transform: rotate(0deg);
          animation-name: rotate360;
          animation-duration: 2000ms;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
        }
        @keyframes rotate360 {
          0% {
            transform: rotate(0deg);
          }
          50% {
            transform: rotate(180deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      `}</style>
    </div>
  )
}
