export const client = () => {
  return typeof window !== 'undefined'
}

export const mobile = () => {
  if (!client()) return false
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    window.navigator.userAgent,
  )
}

export const protocolUrl = (url?: string) => {
  return `${url}`.includes('://')
}

export const object = (target: unknown) => target && typeof target === 'object'

export const url = (str: string) => {
  if (!str || str.length < 10) return false
  let url

  try {
    url = new URL(str)
  } catch (err) {
    return false
  }

  return `${url.protocol}`.startsWith('http')
}

export const validNumber = (val: number | unknown) => {
  if (typeof val !== 'number') return false
  return !Number.isNaN(val)
}
