import React from 'react'
import { HybridLink } from '~cps'
import Twitter from '@geist-ui/icons/twitter'
import Globe from '@geist-ui/icons/globe'
import Mail from '@geist-ui/icons/mail'
import { Spacer, useTheme } from '@geist-ui/core'

export const Footer: React.FC<unknown> = () => {
  const theme = useTheme()

  return (
    <div className="footer">
      <Spacer />
      <div className="links">
        <div className="item">
          <HybridLink
            target="_blank"
            rel="nofollow"
            title="Follow us on Twitter"
            href="https://twitter.com/mest_ai">
            <Twitter />
          </HybridLink>
        </div>
        <div className="item">
          <HybridLink
            title="Mest website"
            target="_blank"
            rel="nofollow"
            href="https://mest.io">
            <Globe />
          </HybridLink>
        </div>
        <div className="item">
          <HybridLink
            title="Email"
            target="_blank"
            rel="nofollow"
            href="mailto:dev@mest.io">
            <Mail />
          </HybridLink>
        </div>
      </div>
      <style jsx>{`
        .footer {
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 100px;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          //backdrop-filter: saturate(180%) blur(5px);
          //background-color: rgba(255, 255, 255, 0.8);
          //box-shadow: 0 0 15px 0 rgb(0 0 0 / 3%);
        }
        .links {
          width: auto;
          height: 50px;
          display: flex;
          align-items: center;
          margin: 15px 30px;
        }
        .item {
          margin: 0 8px;
          color: ${theme.palette.accents_5};
          transition: color 0.2s ease-in-out;
        }
        .item:hover {
          color: ${theme.palette.foreground};
        }
        .item :global(svg) {
          width: 17px;
          height: 17px;
        }
      `}</style>
    </div>
  )
}
