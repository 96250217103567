import React, { useMemo } from 'react'
import { Tabs } from '@geist-ui/core'
import { matchPath, useNavigate, useParams } from 'react-router-dom'

const getPathPattern = (path: string) => ({
  path,
  caseSensitive: false,
  end: true,
})

export const LambdaMenu: React.FC<unknown> = () => {
  const navigate = useNavigate()
  const { lambdaId } = useParams()
  const currentTab = useMemo(() => {
    const path = {
      path: '',
      caseSensitive: false,
      end: true,
    }
    switch (true) {
      case !!matchPath(
        getPathPattern('/board/lambdas/:lambdaId'),
        location.pathname,
      ):
        return 'lambda'
      case !!matchPath(
        getPathPattern('/board/lambdas/:lambdaId/histories'),
        location.pathname,
      ):
        return 'histories'
      case !!matchPath(
        getPathPattern('/board/lambdas/:id/settings'),
        location.pathname,
      ):
        return 'settings'
      default:
        return 'lambda'
    }
  }, [location.pathname])

  const changeHandler = (next: string) => {
    const suffix = next === 'lambda' ? '' : `${next}`
    const url = `/board/lambdas/${lambdaId}/${suffix}`
    navigate(url)
  }

  return (
    <div className="menu">
      <Tabs
        value={currentTab}
        initialValue="lambda"
        hideDivider
        onChange={changeHandler}>
        <Tabs.Item label="Lambda" value="lambda" />
        <Tabs.Item label="Histories" value="histories" />
        <Tabs.Item label="Settings" value="settings" />
      </Tabs>
      <style jsx>{`
        .menu {
          width: 100%;
          max-width: var(--layout-max-width);
          margin: 0 auto;
          display: flex;
        }
        .menu :global(.tabs .content) {
          padding-top: 0;
        }
      `}</style>
    </div>
  )
}
