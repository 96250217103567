import DEVELOPMENT, { type EnvRecord } from './development'
import STAGING from './staging'
import PRODUCTION from './production'
import LOCAL from './local'
import { is, collections } from 'src/pures'

export type { EnvRecord }

export const isDev = (host: string) => {
  return /dev\.|-git-/.test(host)
}
export const isStaging = (host: string) => {
  return /staging\./.test(host)
}
export const isLocalDev = (host: string) => {
  return /localhost/.test(host)
}

export enum ENVS {
  LOCAL = 'LOCAL',
  DEV = 'DEV',
  STAGING = 'STAGING',
  PROD = 'PROD',
}

export const getCurrentEnv = () => {
  if (!is.client()) return ENVS.PROD
  const host = `${location.host}`.toLowerCase()
  return isDev(host)
    ? ENVS.DEV
    : isLocalDev(host)
    ? ENVS.LOCAL
    : isStaging(host)
    ? ENVS.STAGING
    : ENVS.PROD
}

export const getCurrentConstants = (envs: ENVS): EnvRecord => {
  if (envs === ENVS.PROD) {
    return collections.deepDuplicable<EnvRecord>(DEVELOPMENT, PRODUCTION)
  }
  if (envs === ENVS.STAGING) {
    return collections.deepDuplicable<EnvRecord>(DEVELOPMENT, STAGING)
  }
  if (envs === ENVS.LOCAL) {
    return collections.deepDuplicable<EnvRecord>(DEVELOPMENT, LOCAL)
  }
  return DEVELOPMENT
}

export const getServerEnv = (): ENVS | null => {
  if (is.client()) return null
  if (typeof process?.env === 'undefined') return null
  const serverEnv = `${process?.env?.ENV}`.toUpperCase()
  if (serverEnv === ENVS.PROD) return ENVS.PROD
  if (serverEnv === ENVS.STAGING) return ENVS.PROD
  return ENVS.DEV
}
