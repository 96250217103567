import React from 'react'
import { Link, LinkProps, ScaleProps } from '@geist-ui/core'
import { useNavigate } from 'react-router-dom'

export type HybridLinkProps = LinkProps &
  ScaleProps & {
    onRedirect?: (to: string) => void
  }

export const HybridLink: React.FC<HybridLinkProps> = ({
  href = '#',
  children,
  onRedirect,
  ...props
}) => {
  const navigate = useNavigate()

  const isRelativeUrl = !/^([a-z0-9]*:|.{0})\/\/.*$/gim.test(href)

  const clickHandler = (event: React.MouseEvent<HTMLAnchorElement>) => {
    onRedirect && onRedirect(href)
    if (isRelativeUrl) {
      event.preventDefault()
      event.stopPropagation()
      navigate(href)
    }
  }

  return (
    <Link href={href} onClick={clickHandler} {...props}>
      {children}
    </Link>
  )
}
