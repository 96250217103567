import React from 'react'
import { Button, Grid, Text, Spacer, useMediaQuery, useTheme } from '@geist-ui/core'
import { LogoWithText } from '~assets/images'

export type RainbowProps = {
  isRefreshing?: boolean
  onLogin: () => void
}

export const Rainbow: React.FC<RainbowProps> = ({ isRefreshing, onLogin }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery('xs')

  return (
    <div className={`rainbow ${theme.type === 'light' ? 'dot' : ''}`}>
      <div className="banner-logo">
        <LogoWithText />
      </div>
      <Grid.Container className="titles">
        <Grid xs={24} justify="center" alignItems="center">
          <Text h1 className="h1" font={isMobile ? '37px' : '50px'} type="default">
            <span className="colorful">Lambda</span>, more automatic Lambda.
          </Text>
        </Grid>
        <Grid xs={24} justify="center" alignItems="center">
          <Text font={isMobile ? '14px' : '16px'} className="intro">
            On schedule, lambda will automatically start sailing, including{' '}
            <span className="keyword">handling timed tasks</span>,{' '}
            <span className="keyword">failing to retry</span>,{' '}
            <span className="keyword">adjusting the concurrent</span>, etc. No
            burden,{' '}
            <Text span b type="success">
              start your great work instantly!
            </Text>
          </Text>
        </Grid>
        <Grid xs={24} sm={0}>
          <Spacer h={3.5} />
        </Grid>
        <Grid xs={0} sm={24}>
          <Spacer h={1.5} />
        </Grid>
        <Grid
          xs={24}
          sm={11}
          alignItems="center"
          justify={isMobile ? 'center' : 'flex-end'}>
          <Button shadow type="abort" w={isMobile ? '85%' : 'auto'}>
            Documentation
          </Button>
        </Grid>
        <Grid xs={0} sm={2} />
        <Grid xs={24} sm={0} style={{ height: '20px' }} />
        <Grid
          xs={24}
          sm={11}
          alignItems="center"
          justify={isMobile ? 'center' : 'flex-start'}>
          <Button
            shadow
            w={isMobile ? '85%' : 'auto'}
            loading={isRefreshing}
            type="secondary-light"
            onClick={onLogin}>
            {isRefreshing ? 'Refreshing tokens...' : 'Sign in with Google'}
          </Button>
        </Grid>
      </Grid.Container>
      <style jsx>{`
        .rainbow {
          width: 100%;
          height: 100%;
          background-color: ${theme.palette.background};
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-feature-settings: 'calt';
          font-variant-ligatures: common-ligatures;
          text-shadow: rgb(0 0 0 / 1%) 0 0 1px;
        }
        .dot {
          background-image: radial-gradient(#e3e3e3 0, transparent 2px),
            radial-gradient(#e3e3e3 1px, transparent 3px);
          background-position: 0 0, 35px 35px;
          background-attachment: fixed;
          background-size: 70px 70px;
        }
        .banner-logo {
          position: fixed;
          top: 20px;
          left: 25px;
          color: ${theme.palette.foreground};
        }
        .banner-logo :global(svg) {
          width: 70px;
        }
        .rainbow :global(.titles) {
          width: 90vw;
          max-width: 850px;
          color: ${theme.palette.accents_6};
          margin-top: -250px;
        }
        .rainbow :global(.h1 span) {
          text-align: center;
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
          -ms-text-fill-color: transparent;
          background-image: linear-gradient(
            135deg,
            #0e73cc 1.93%,
            #624bbb 14.86%,
            #ff455d 48.09%,
            #f35815 77.82%,
            #f2b600 97.3%
          );
          line-height: 1.5em;
        }
        .rainbow :global(.intro) {
          color: ${theme.palette.accents_6};
          text-align: center;
          margin-top: ${isMobile ? '20px' : 0};
          max-width: 700px;
        }
        .rainbow :global(.keyword) {
          position: relative;
          cursor: ne-resize;
          transition: color 150ms linear;
          white-space: pre;
        }
        .rainbow :global(.keyword:hover) {
          color: ${theme.palette.accents_7};
        }
        .rainbow :global(.keyword:hover:after) {
          bottom: -3px;
          opacity: 0.8;
        }
        .rainbow :global(.keyword:after) {
          position: absolute;
          opacity: 0.3;
          content: '';
          bottom: -4px;
          left: 0;
          right: 0;
          width: 100%;
          height: 2px;
          background-color: ${theme.palette.accents_5};
          transition: all 200ms linear;
          border-radius: 20px;
        }
        .rainbow :global(*::selection) {
          background-color: rgba(255, 255, 255, 0.1);
          color: unset;
        }
      `}</style>
    </div>
  )
}
