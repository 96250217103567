import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useGetTasksQuery } from '~store'
import { HistoriesItem, ActionsLayout, MestLoading } from '~cps'
import { Input, Pagination, Select, Spacer, useInput } from '@geist-ui/core'
import Search from '@geist-ui/icons/search'

const EXCLUDE_KEY = 'ALL'
const FILTER_STATUS = [EXCLUDE_KEY, '200', '204', '401', '403', '404', '500', '503']
const FILTER_TYPES = [EXCLUDE_KEY, 'USER', 'CRON']
const improveExcludeLabel = (
  value: typeof FILTER_STATUS[number] | typeof FILTER_TYPES[number],
  label?: string,
): string => {
  if (value === EXCLUDE_KEY) return `All ${label}`
  return value
}
const getFilterValue = (
  value: typeof FILTER_STATUS[number] | typeof FILTER_TYPES[number],
):
  | Omit<typeof FILTER_STATUS[number], typeof EXCLUDE_KEY>
  | Omit<typeof FILTER_TYPES[number], typeof EXCLUDE_KEY>
  | undefined => {
  return value.toUpperCase().includes(EXCLUDE_KEY) ? undefined : value
}

export const LambdaHistories: React.FC<unknown> = () => {
  const { lambdaId } = useParams()
  const [code, setCode] = useState<string>(EXCLUDE_KEY)
  const [type, setType] = useState<string>(EXCLUDE_KEY)
  const [page, setPage] = useState<number>(1)
  const { bindings, state: filter } = useInput('')

  const { tasks, count, isLoading } = useGetTasksQuery(
    {
      lambdaId: lambdaId!,
      page,
      filter,
      code: getFilterValue(code),
      from: getFilterValue(type),
    },
    {
      skip: !lambdaId,
      selectFromResult: ({ data, isLoading, isFetching }) => ({
        tasks: data?.tasks,
        count: data?.count,
        isLoading: isLoading || isFetching,
      }),
    },
  )

  return (
    <div>
      <ActionsLayout>
        <div className="filters">
          <Input
            className="keywords"
            icon={<Search />}
            placeholder="Input version name"
            scale={2 / 3}
            w="200px"
            clearable
            {...bindings}
          />
          <Select
            className="status"
            placeholder="Status"
            width="100px"
            ml="10px"
            scale={2 / 3}
            disableMatchWidth
            value={code}
            onChange={val => setCode(val as string)}>
            {FILTER_STATUS.map(item => (
              <Select.Option value={item} key={item}>
                {improveExcludeLabel(item, 'Status')}
              </Select.Option>
            ))}
          </Select>
          <Select
            className="status"
            placeholder="Type"
            width="100px"
            ml="10px"
            scale={2 / 3}
            disableMatchWidth
            value={type}
            onChange={val => setType(val as string)}>
            {FILTER_TYPES.map(item => (
              <Select.Option value={item} key={item}>
                {improveExcludeLabel(item, 'Types')}
              </Select.Option>
            ))}
          </Select>
        </div>
        <Pagination
          onChange={setPage}
          initialPage={1}
          page={page}
          limit={5}
          count={count}
          scale={2 / 3}
        />
      </ActionsLayout>

      <MestLoading visible={isLoading} empty={tasks?.length === 0}>
        {tasks
          ? tasks.map((item, index) => <HistoriesItem data={item} key={index} />)
          : null}
      </MestLoading>

      <Spacer />
      <style jsx>{`
        .filters {
          width: auto;
          height: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
        .filters :global(.status) {
          min-width: 50px;
        }
        .filters :global(.keywords) {
          height: 100%;
        }
        .filters :global(.with-label) {
          height: 100%;
        }
      `}</style>
    </div>
  )
}
