import React, { useMemo } from 'react'
import { Text } from '@geist-ui/core'
import { useGetLatestTasksQuery } from '~store'
import { MestLoading, TaskItem, TaskListWithRelationsStacked } from '~cps'

export const Overview: React.FC<unknown> = () => {
  const { data, isLoading, isFetching } = useGetLatestTasksQuery()
  const tasksWithChildren = useMemo(() => {
    if (!data) return undefined
    return data.reduce<Array<TaskListWithRelationsStacked>>((acc, task) => {
      if (!acc.length) return [task]
      const last = acc[acc.length - 1]
      if (last.version !== task.version) return [...acc, task]

      const lastWithChildren = {
        ...last,
        children: [...(last.children || [last]), task],
      }
      return acc.map(item =>
        item.id === lastWithChildren.id ? lastWithChildren : item,
      )
    }, [])
  }, [data])

  return (
    <div>
      <Text h3>Activity</Text>
      <div className="activities">
        <MestLoading visible={isLoading || isFetching} empty={data?.length === 0}>
          {tasksWithChildren?.map((task, index) => (
            <TaskItem key={index} task={task} />
          ))}
        </MestLoading>
      </div>
      <style jsx>{`
        .activities {
          width: 100%;
          padding-left: 10px;
        }
      `}</style>
    </div>
  )
}
