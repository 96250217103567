import React, { useMemo } from 'react'
import { Tabs } from '@geist-ui/core'
import { matchPath, useNavigate, useMatch } from 'react-router-dom'
import { LambdaMenu } from './lambda-menu'

export const HomeMenu: React.FC<unknown> = () => {
  const navigate = useNavigate()
  const isLambdaDetails = useMatch({
    path: '/board/lambdas/:lambdaId',
    caseSensitive: false,
    end: false,
  })
  const isLambdaCreate = useMatch({
    path: '/board/lambdas/create',
    caseSensitive: false,
    end: false,
  })
  const currentTab = useMemo(() => {
    switch (true) {
      case !!matchPath('/board/overview', location.pathname):
        return 'overview'
      case !!matchPath('/board/lambdas', location.pathname):
        return 'lambdas'
      case !!matchPath('/board/lambdas/create', location.pathname):
        return 'lambdas'
      default:
        return 'overview'
    }
  }, [location.pathname])

  const changeHandler = (next: string) => {
    navigate(next)
  }

  if (isLambdaDetails && !isLambdaCreate) return <LambdaMenu />

  return (
    <div className="menu">
      <Tabs
        value={currentTab}
        initialValue="overview"
        hideDivider
        onChange={changeHandler}>
        <Tabs.Item label="overview" value="overview" />
        <Tabs.Item label="lambdas" value="lambdas" />
      </Tabs>
      <style jsx>{`
        .menu {
          width: 100%;
          max-width: var(--layout-max-width);
          margin: 0 auto;
        }
        .menu :global(.tabs .content) {
          padding-top: 0;
        }
      `}</style>
    </div>
  )
}
