import React from 'react'
import { GlobalErrors } from '~cps'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import {
  Home,
  Dashboard,
  Overview,
  Logout,
  Profile,
  Lambdas,
  LambdaDetails,
  LambdaCreate,
  LambdaHistories,
  LambdasSettings,
} from './pages'
const RootRoutes = () => {
  return (
    <Routes>
      <Route path="/board" element={<Dashboard />}>
        <Route index element={<Overview />} />
        <Route path="profile" element={<Profile />} />
        <Route path="overview" element={<Overview />} />
        <Route path="lambdas" element={<Lambdas />} />
        <Route path="lambdas/:lambdaId" element={<LambdaDetails />} />
        <Route path="lambdas/:lambdaId/histories" element={<LambdaHistories />} />
        <Route path="lambdas/:lambdaId/settings" element={<LambdasSettings />} />
        <Route path="lambdas/create" element={<LambdaCreate />} />
      </Route>
      <Route path="/logout" element={<Logout />} />
      <Route path="/" element={<Home />} />
    </Routes>
  )
}

const AppRouter: React.FC<unknown> = () => (
  <BrowserRouter>
    <GlobalErrors />
    <RootRoutes />
  </BrowserRouter>
)

export default AppRouter
