import React from 'react'

export const MestNodata: React.FC<unknown> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none">
      <ellipse
        cx="28.0001"
        cy="19.3333"
        rx="9.33333"
        ry="3.33333"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.3334 28C37.3334 29.8409 33.1547 31.3333 28.0001 31.3333C22.8454 31.3333 18.6667 29.8409 18.6667 28"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.3334 36.6666C37.3334 38.5076 33.1547 40 28.0001 40C22.8454 40 18.6667 38.5076 18.6667 36.6666"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.6667 19.2133V36.6667"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.3333 19.2133V36.6667"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="28" cy="28" r="27" stroke="currentColor" strokeWidth="2" />
    </svg>
  )
}
