import React, { useMemo } from 'react'
import { Dot, Text, DotProps } from '@geist-ui/core'

export type HistoryResponseCodeLabelProps = {
  code: string | null
}

export const HistoryResponseCodeLabel: React.FC<HistoryResponseCodeLabelProps> = ({
  code,
}) => {
  const dotType = useMemo<DotProps['type']>(() => {
    const codeString = `${code}`
    switch (true) {
      case codeString.startsWith('2'):
        return 'success'
      case codeString.startsWith('5'):
        return 'error'
      case codeString.startsWith('4'):
        return 'warning'
      default:
        return 'default'
    }
  }, [code])

  return (
    <div className="response-code">
      <Dot type={dotType}>
        <Text span type="secondary">
          {code}
        </Text>
      </Dot>

      <style jsx>{`
        .response-code {
          width: 100%;
          height: auto;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      `}</style>
    </div>
  )
}
