import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import durationFormat from 'dayjs/plugin/duration'
import utcFormat from 'dayjs/plugin/utc'
import relativeTimeFormat from 'dayjs/plugin/relativeTime'
dayjs.extend(localizedFormat)
dayjs.extend(utcFormat)
dayjs.extend(durationFormat)
dayjs.extend(relativeTimeFormat)

export const day = dayjs

export const toDate = (
  date: string | Date,
  format: string = 'YYYY/MM/DD HH:mm:ss',
) => {
  return dayjs(date).format(format)
}

export const toDurations = (millisecond?: number | string | null) => {
  if (!millisecond || Number.isNaN(+millisecond)) return null
  const date = Date.now() + Number(millisecond)
  return dayjs().from(dayjs(date), true)
}

export const dateToDurations = (date?: Date | null) => {
  if (!date) return toDurations(null)
  const now = Date.now()
  const diff = new Date(date).getTime() - now
  return toDurations(diff)
}

export const toCapitalize = (str?: string): string => {
  if (!str) return ''
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const versionToHash = (version?: string | null): string => {
  if (!version) return ''
  return version.split('-').reverse()[0]
}
