import { BaseApi } from './base'

export type Lambda = {
  id: number
  endpoint: string
  pulumi?: string | null
  interval: number
  concurrency: number
  name: string
  description?: string | null
  accessKey?: string | null
  github?: string | null
  creatorId: string | null
  activated: boolean
  paused?: boolean
  createdAt: Date
  updatedAt: Date
  nextCycleAt?: Date
}

export type LambdaCounts = {
  tags: 0
  tasks: 0
}

export type LambdaList = Array<
  Lambda & {
    _count: LambdaCounts
  }
>

export type LambdaCreateInput = Pick<
  Lambda,
  | 'endpoint'
  | 'pulumi'
  | 'name'
  | 'description'
  | 'accessKey'
  | 'github'
  | 'interval'
  | 'concurrency'
>

export type LambdaUpdateInput = Partial<
  Pick<
    Lambda,
    | 'endpoint'
    | 'pulumi'
    | 'description'
    | 'accessKey'
    | 'github'
    | 'interval'
    | 'concurrency'
    | 'paused'
  >
> & {
  name: string
}

export const lambdasAPI = BaseApi.injectEndpoints({
  endpoints: ({ query, mutation }) => ({
    getLambdaOne: query<Lambda, { id: string }>({
      query: ({ id }) => `/lambdas/${id}`,
      providesTags: ['Lambda'],
    }),
    getLambdas: query<LambdaList, { filter?: string }>({
      query: ({ filter }) => `/lambdas?filter=${filter}`,
      providesTags: ['Lambdas'],
    }),
    createLambda: mutation<Lambda, LambdaCreateInput>({
      query: lambda => ({
        url: `/lambdas`,
        body: lambda,
        method: 'POST',
      }),
      invalidatesTags: ['Lambdas'],
    }),
    updateLambdaById: mutation<Lambda, { id: string; body: LambdaUpdateInput }>({
      query: ({ id, body }) => ({
        url: `/lambdas/${id}`,
        body: body,
        method: 'PUT',
      }),
      invalidatesTags: ['Lambda'],
    }),
    removeLambdaById: mutation<Lambda, { id: string }>({
      query: ({ id }) => ({
        url: `/lambdas/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useCreateLambdaMutation,
  useGetLambdasQuery,
  useGetLambdaOneQuery,
  useUpdateLambdaByIdMutation,
  useRemoveLambdaByIdMutation,
} = lambdasAPI
