import React, { useEffect } from 'react'
import { Task } from '~store'
import { Grid, useTheme, Text, useToasts } from '@geist-ui/core'
import { formats } from '~pures'
import { HistoryVersionLabel } from './version-label'
import { HistoryResponseCodeLabel } from './response-code'
import { HistoryTimeLabel } from './time'
import { HistoryDateLabel } from './date'
import { HistoryConcurrency } from './concurrency'
import { HistoryFrom } from './from'
import { HistoryMore } from './more'

export type HistoriesItemProps = {
  data: Task
}

export const HistoriesItem: React.FC<HistoriesItemProps> = ({ data }) => {
  const theme = useTheme()

  return (
    <div className="history">
      <Grid.Container w="100%" h="100%">
        <Grid alignItems="center" justify="center" h="100%" xs={6}>
          <HistoryVersionLabel version={data.version} />
        </Grid>
        <Grid alignItems="center" h="100%" xs={3}>
          <HistoryResponseCodeLabel code={data.responseCode} />
        </Grid>
        <Grid alignItems="center" justify="center" h="100%" xs={4}>
          <HistoryTimeLabel time={data.performanceTime} />
        </Grid>
        <Grid alignItems="center" justify="center" h="100%" xs={3}>
          <HistoryConcurrency
            current={data.current}
            concurrency={data.concurrency}
          />
        </Grid>
        <Grid alignItems="center" justify="center" h="100%" xs={3}>
          <HistoryFrom from={data.from} />
        </Grid>
        <Grid alignItems="center" justify="flex-end" direction="row" h="100%" xs>
          <HistoryDateLabel date={data.updatedAt} />
        </Grid>
        <Grid alignItems="center" justify="flex-end" direction="row" h="100%" xs={1}>
          <HistoryMore data={data} />
        </Grid>
      </Grid.Container>
      <style jsx>{`
        .history {
          width: 100%;
          height: 78px;
          border: 1px solid ${theme.palette.border};
          border-top-left-radius: ${theme.layout.radius};
          border-top-right-radius: ${theme.layout.radius};
        }
        .history + .history {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          margin-top: -1px;
          border-top: none;
        }
        .history:last-of-type {
          border-bottom-left-radius: ${theme.layout.radius};
          border-bottom-right-radius: ${theme.layout.radius};
        }
      `}</style>
    </div>
  )
}
