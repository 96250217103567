import * as is from './is'

export const deepDuplicable = <T extends Record<string, unknown>>(
  source: T,
  target: Partial<T>,
): T => {
  if (!is.object(target) || !is.object(source)) return source as T

  const sourceKeys = Object.keys(source) as Array<keyof T>
  let result = {} as any
  for (const key of sourceKeys) {
    const sourceValue = source[key]
    const targetValue = target[key]

    if (Array.isArray(sourceValue) && Array.isArray(targetValue)) {
      result[key] = targetValue.concat(sourceValue)
    } else if (is.object(sourceValue) && is.object(targetValue)) {
      result[key] = deepDuplicable(sourceValue as Record<string, unknown>, {
        ...(targetValue as Record<string, unknown>),
      })
    } else if (targetValue) {
      result[key] = targetValue
    } else {
      result[key] = sourceValue
    }
  }
  return result
}

export const generateId = (): string => Math.random().toString(36).substr(2, 9)
