import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { SerializedError } from '@reduxjs/toolkit'

export type QueryError = {
  error: FetchBaseQueryError | SerializedError
}
export type ApiError = {
  data?: {
    error: string
    message?: string | string[]
  }
  message?: string
}

export type SemanticError = {
  title?: string
  message: string
}

const notNullObject = (input: any): input is Object =>
  typeof input === 'object' && !Object.is(input, null)

const isApiError = (error: any): error is ApiError => {
  return notNullObject(error) && ('data' in error || 'message' in error)
}

export const getSemanticError = (error?: unknown): SemanticError => {
  if (!isApiError(error)) return { title: '', message: '' }
  const messages = error?.data?.message || error?.message
  const message = (Array.isArray(messages) ? messages : [messages])[0] || ''
  const { data } = error
  if (!data) return { title: '', message }

  return {
    title: data.error,
    message: message || '',
  }
}

export const isQueryError = <T>(errorOrData: QueryError | T): errorOrData is QueryError => {
  return notNullObject(errorOrData) && 'error' in errorOrData
}

export const isFetchBaseQueryError = (error: unknown): error is FetchBaseQueryError => {
  return notNullObject(error) && 'status' in error
}

export const getQueryErrorMessage = (
  error: FetchBaseQueryError | SerializedError,
): SemanticError => {
  return getSemanticError(error)
}
