import React from 'react'
import MoreVertical from '@geist-ui/icons/moreVertical'
import {
  Code,
  Modal,
  Popover,
  useClasses,
  useModal,
  useToasts,
  Text,
} from '@geist-ui/core'
import { isQueryError, Task, useCreateCloneMutation } from '~store'
import { PrismCode } from '~cps'

export type HistoryMoreProps = {
  data: Task
}

export const HistoryMore: React.FC<HistoryMoreProps> = ({ data }) => {
  const { setToast } = useToasts()
  const { bindings, setVisible: setResponseVisible } = useModal()
  const isReporting = data.responseCode === `${204}` && data.response === 'pending'

  const [clone, { isLoading: cloning }] = useCreateCloneMutation()
  const cloneHandler = async () => {
    const result = await clone({ id: data.id })
    if (isQueryError(result)) return
    setToast({
      text: `Version "${data.version}" has been re-run`,
      type: 'success',
    })
  }

  const content = () => (
    <>
      <Popover.Item
        className="history-popover-item"
        onClick={() => setResponseVisible(true)}>
        <span>View all response</span>
      </Popover.Item>
      <Popover.Item
        className={useClasses('history-popover-item', { loading: cloning })}
        onClick={cloneHandler}>
        <span>Run again</span>
      </Popover.Item>
      <Popover.Item className="history-popover-item disabled">
        <span>Copy cURL</span>
      </Popover.Item>
    </>
  )

  return (
    <div className="more">
      <Modal {...bindings}>
        <Modal.Title>
          <Code>
            TASK.{data.id}.[{data.current}/{data.concurrency}].{data.responseCode}
          </Code>
        </Modal.Title>
        <Modal.Subtitle>{data.version}</Modal.Subtitle>
        <Modal.Content>
          {isReporting ? (
            <Text py="20px" style={{ textAlign: 'center' }}>
              No data, pending reporting
            </Text>
          ) : (
            <PrismCode json={data.response} />
          )}
        </Modal.Content>
        <Modal.Action onClick={() => setResponseVisible(false)}>OK</Modal.Action>
      </Modal>
      <Popover
        portalClassName="history-popover"
        trigger="click"
        content={content}
        placement="bottomEnd"
        offset={5}
        hideArrow>
        <MoreVertical size={15} />
      </Popover>
      <style jsx>{`
        .more {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
        .more :global(svg) {
          width: 16px;
          height: 16px;
          display: inline-block;
        }
        :global(div.history-popover-item.item) {
          cursor: pointer;
          user-select: none;
        }
        :global(div.history-popover-item.loading) {
          cursor: not-allowed !important;
          opacity: 0.5;
          pointer-events: none;
        }
        :global(div.history-popover-item.disabled) {
          cursor: not-allowed !important;
          opacity: 0.5;
          pointer-events: none;
        }
        :global(div.history-popover.tooltip-content) {
          width: 160px;
        }
      `}</style>
    </div>
  )
}
