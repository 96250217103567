import React from 'react'
import { useTheme } from '@geist-ui/core'
import { LogoWithText } from '~assets/images'

export const RedirectLayout: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const theme = useTheme()

  return (
    <div className="redirect">
      <header>
        <p className="page-title">Auth</p>
        <p className="page-desc">
          Verify your information, this step usually takes a few seconds.
        </p>
      </header>
      <div className="content">{children}</div>
      <div className="footer">
        <main>
          <p className="powered-by">Powered by</p>
          <LogoWithText />
        </main>
      </div>
      <style jsx>{`
        .redirect {
          margin: 0 auto;
          width: 90%;
          max-width: calc(var(--layout-max-width) - 100px);
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
        }
        header {
          width: 100%;
          border-bottom: 1px solid ${theme.palette.border};
          padding: 90px 0 50px 0;
        }
        .page-title {
          font-size: 40px;
          font-weight: 800;
          line-height: 1;
          margin: 0;
          font-family: ${theme.font.prism};
          text-shadow: 1px 3px 3px ${theme.palette.border};
          text-transform: uppercase;
        }
        .page-desc {
          font-size: 16px;
          font-weight: normal;
          color: ${theme.palette.accents_5};
          font-family: ${theme.font.prism};
        }
        .content {
          width: 100%;
          height: 100%;
          padding-top: 20px;
        }
        .content :global(p) {
          font-family: ${theme.font.prism};
          font-weight: 450;
        }
        .footer {
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 170px;
        }
        main {
          width: 80%;
          height: 100%;
          max-width: var(--layout-max-width);
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 auto;
          border-top: 1px solid ${theme.palette.accents_2};
          user-select: none;
        }
        main :global(svg) {
          width: 60px;
          margin-left: 10px;
        }
        .powered-by {
          font-family: ${theme.font.prism};
          font-weight: 600;
          font-size: 17px;
        }
      `}</style>
    </div>
  )
}
