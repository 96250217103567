import { DependencyList, useEffect } from 'react'
import { useToasts } from '@geist-ui/core'

export type AsyncEffectCallbackMounted = () => boolean

export type NativeEffectCallback = (
  isMounted: AsyncEffectCallbackMounted,
) => void | (() => void | undefined)

export type AsyncEffectCallback = (
  isMounted: AsyncEffectCallbackMounted,
) => Promise<void | (() => void | undefined)>

export type CatchCallback = (error: Error) => void

export const useAsync = (
  effect: AsyncEffectCallback | NativeEffectCallback,
  deps: DependencyList,
  catchHandler?: CatchCallback,
) => {
  let mounted = true
  const {setToast} = useToasts()
  const defaultCatchHandler = (error: Error) => {
    setToast({ text: error.message, type: 'warning' })
  }
  const catchFn = catchHandler || defaultCatchHandler

  useEffect(() => {
    Promise.resolve(effect(() => mounted)).catch(catchFn)
    return () => {
      mounted = false
    }
  }, deps)
}
