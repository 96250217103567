import React, { useImperativeHandle } from 'react'
import { Text, Input, Collapse, useInput } from '@geist-ui/core'
import Lock from '@geist-ui/icons/lock'

export type LambdaAuthValues = {
  accessKey: string
} | null
export type LambdaAuthRef = {
  getValuesBeforeSubmit: () => LambdaAuthValues
}
export type LambdaAuthProps = {
  accessKey?: string | null
}

export const LambdaAuth = React.forwardRef<LambdaAuthRef, LambdaAuthProps>(
  ({ accessKey }, ref) => {
    const { state: authState, bindings: authBinding } = useInput(
      `${accessKey || ''}`,
    )
    useImperativeHandle(ref, () => ({
      getValuesBeforeSubmit,
    }))

    const getValuesBeforeSubmit = (): LambdaAuthValues => {
      return {
        accessKey: authState,
      }
    }

    return (
      <Collapse shadow title="Auth" className="lambda-auth">
        <div className="item">
          <Text mt={0} font="14px" type="secondary">
            <Text span type="warning">
              [OPTIONAL]
            </Text>{' '}
            The schedule server will make a request to the function using a Bearer
            token that.
          </Text>
          <Input.Password
            w="400px"
            icon={<Lock />}
            scale={2 / 3}
            placeholder="Bearer token"
            clearable
            {...authBinding}
          />
        </div>
        <style jsx>{`
          .item {
            padding: 0;
            position: relative;
          }
          .tips {
            position: absolute;
            right: 0;
            top: 6px;
          }
          :global(.lambda-auth.collapse .content) {
            padding: 0;
          }
        `}</style>
      </Collapse>
    )
  },
)
