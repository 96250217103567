const development = {
  ENV_LABEL: 'DEVELOPMENT',

  UNAUTH_PATH: 'login',

  API_ORIGIN: 'https://dev.api.schedule.mest.sh/api/v1',
}

export default development

export type EnvRecord = typeof development
