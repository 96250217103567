import React from 'react'
import { Text } from '@geist-ui/core'

export type HistoryVersionLabelLabelProps = {
  version: string
}

export const HistoryVersionLabel: React.FC<HistoryVersionLabelLabelProps> = ({
  version,
}) => {
  return (
    <div className="version">
      <Text my={0} font="14px" className="label">
        {version}
      </Text>
      <Text my={0} type="secondary" font="14px">
        Version
      </Text>
      <style jsx>{`
        .version {
          display: inline-flex;
          width: 100%;
          height: auto;
          flex-direction: column;
          padding-left: 16px;
        }
        .version :global(.label) {
          word-break: keep-all;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      `}</style>
    </div>
  )
}
