import React from 'react'
import {
  Text,
  Collapse,
  Button,
  useTheme,
  useModal,
  Modal,
  Code,
  useToasts,
} from '@geist-ui/core'
import { isQueryError, Lambda, useRemoveLambdaByIdMutation } from '~store'
import { useNavigate } from 'react-router-dom'

export type LambdaDestroyProps = {
  lambda: Lambda
}

export const LambdaDestroy: React.FC<LambdaDestroyProps> = ({ lambda }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { bindings, setVisible } = useModal()
  const { setToast } = useToasts()
  const [removeLambda, { isLoading }] = useRemoveLambdaByIdMutation()

  const destroyHandler = () => {
    setVisible(true)
  }
  const cancelHandler = () => {
    setVisible(false)
    setToast({
      text: 'Already cancelled, nothing has changed.',
    })
  }
  const submitHandler = async () => {
    const result = await removeLambda({ id: `${lambda.id}` })
    setVisible(false)
    if (isQueryError(result)) return
    setToast({
      text: `Lambda "${lambda.name}" has been removed.`,
      type: 'success',
    })
    navigate('/board/lambdas')
  }

  return (
    <Collapse shadow title="Dangerous" className="lambda-destroy">
      <Modal {...bindings}>
        <Modal.Title>Destroy Lambda</Modal.Title>
        <Modal.Content>
          <p className="modal-tips">
            Are you sure to remove <Code>{lambda.name}</Code>?
          </p>
          <p className="modal-tips-desc">the operation is not recoverable</p>
        </Modal.Content>
        <Modal.Action passive onClick={cancelHandler} loading={isLoading}>
          Cancel
        </Modal.Action>
        <Modal.Action onClick={submitHandler} loading={isLoading}>
          <Text span type="error">
            Submit
          </Text>
        </Modal.Action>
      </Modal>
      <div className="item">
        <Text mt={0} font="14px" type="error">
          All timed tasks will be cancelled as soon as lambda is destroyed.
        </Text>
        <Button
          auto
          font="14px"
          mt="20px"
          type="error-light"
          onClick={destroyHandler}>
          Destroy Lambda
        </Button>
      </div>
      <style jsx>{`
        .item {
          padding: 0;
          position: relative;
        }
        .tips {
          position: absolute;
          right: 0;
          top: 6px;
        }
        .modal-tips {
          text-align: center;
        }
        .modal-tips-desc {
          text-align: center;
          text-transform: uppercase;
          color: ${theme.palette.error};
        }
        :global(.lambda-destroy.collapse .content) {
          padding: 0;
        }
        :global(.lambda-destroy .title) {
          color: ${theme.palette.error};
        }
      `}</style>
    </Collapse>
  )
}
