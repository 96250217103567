import React from 'react'
import { useTheme } from '@geist-ui/core'

export const SettingCard: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const theme = useTheme()

  return (
    <div className="setting">
      {children}
      <style jsx>{`
        .setting {
          width: 100%;
          height: auto;
          border: 1px solid ${theme.palette.border};
          border-radius: ${theme.layout.radius};
          position: relative;
          padding: 0 20px;
        }
      `}</style>
    </div>
  )
}
