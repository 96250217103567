import React from 'react'
import Users from './users'
import { Spacer, useTheme } from '@geist-ui/core'
import { LogoWithText } from '~assets/images'
import { HybridLink } from '~cps'

export const GlobalHeader: React.FC<unknown> = () => {
  const theme = useTheme()

  return (
    <div className="container">
      <HybridLink href="/board" title="Go back to dashboard">
        <div className="home">
          <LogoWithText />
        </div>
      </HybridLink>

      <Spacer />
      <Users />
      <style jsx>{`
        .container {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          max-width: var(--layout-max-width);
          margin: 0 auto;
        }
        .home {
          color: ${theme.palette.foreground};
          padding-left: 15px;
          cursor: pointer;
          transition: opacity 200ms linear;
        }
        .home:hover {
          opacity: 0.82;
        }
        .home :global(svg) {
          width: 70px;
        }
      `}</style>
    </div>
  )
}
