import React from 'react'
import { useParams } from 'react-router-dom'
import {
  isQueryError,
  useGetLambdaOneQuery,
  useUpdateLambdaByIdMutation,
} from '~store'
import { MestEmpty, MestLoading } from '~cps'
import { Button, Text, useToasts } from '@geist-ui/core'

export const LambdaShortcuts: React.FC<unknown> = () => {
  const { lambdaId } = useParams()
  const { setToast } = useToasts()
  const { data, isLoading, isFetching } = useGetLambdaOneQuery(
    { id: lambdaId! },
    { skip: !lambdaId },
  )
  const [update, { isLoading: isUpdating }] = useUpdateLambdaByIdMutation()
  const togglePause = async () => {
    const result = await update({
      id: lambdaId!,
      body: { name: data!.name, paused: !data?.paused },
    })
    if (isQueryError(result)) return
    setToast({
      text: `Lambda "${data!.name}" has ${
        !data?.paused ? 'stopped' : 'resumed'
      } working.`,
      type: 'success',
    })
  }

  if (isLoading || isFetching) return <MestLoading visible />
  if (!data?.id) return <MestEmpty visible />

  return (
    <div className="summary">
      <Text h3 font="16px" className="title">
        Shortcuts
      </Text>
      <div>
        <Button
          auto
          scale={2 / 3}
          type={data?.paused ? 'success-light' : 'warning-light'}
          loading={isUpdating}
          onClick={togglePause}>
          {data?.paused ? 'Resume paused' : 'Pause Lambda'}
        </Button>
      </div>
      <style jsx>{`
        .summary {
          width: 100%;
          border-radius: 5px;
          overflow: hidden;
        }
      `}</style>
    </div>
  )
}
