import React from 'react'
import { useTheme, Page } from '@geist-ui/core'

export type LayoutProps = {
  row?: boolean
}

export const Layout: React.FC<React.PropsWithChildren<LayoutProps>> = ({
  row = false,
  children,
}) => {
  const theme = useTheme()

  return (
    <Page className="layout-main" w="100%" padding={0}>
      <Page.Body padding={0}>{children}</Page.Body>

      <style global jsx>{`
        html,
        body {
          background-color: ${theme.palette.accents_1};
        }
        section.layout-main {
          min-height: 0 !important;
          background: ${theme.palette.accents_1};
        }
        section.layout-main * {
          box-sizing: border-box;
        }
      `}</style>
    </Page>
  )
}
